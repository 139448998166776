import React, { useState, useEffect, useRef } from "react";
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from 'react-bootstrap/Button'
import FormSelect from 'react-bootstrap/FormSelect'
import { HexColorPicker } from "react-colorful";

const Playground = () => {

    const canvas = useRef(null);
    const [canvasWidth, setCanvasWidth] = useState(512);
    const [canvasHeight, setCanvasHeight] = useState(512);
    const [image, setImage] = useState(null);
    const [topText, setTopText] = useState("");
    const [bottomText, setBottomText] = useState("");
    const [textAlign, setTextAlign] = useState("left");
    const [textAlignVertical, setTextAlignVertical] = useState("bottom");
    const [textSize, setTextSize] = useState("30");
    const [textFont, setTextFont] = useState("Verdana");
    const [textFontColor, setTextFontColor] = useState("black");
    const [textFontBackgroundColor, setTextFontBackgroundColor] = useState("white");

    useEffect(() => {
        const catImage = new Image();
        catImage.src = "https://thiscatdoesnotexist.com"
        catImage.onload = () => setImage(catImage);
    }, [])

    useEffect(() => {
        updateCanvas();
    }, [image, canvas, topText, bottomText, textAlign, textAlignVertical, textFont, textFontColor, textFontBackgroundColor])

    const updateCanvas = () => {
        // console.log('updateCanvas');
        if (image && canvas) {
            const ctx = canvas.current.getContext("2d");
            ctx.drawImage(image, 0, 0);

            // ctx.font = `${textSize}px Comic Sans MS`
            ctx.font = `${textSize}px ${textFont}`
            ctx.textAlign = textAlign;

            let topMeasure = ctx.measureText(topText);
            let bottomMeasure = ctx.measureText(bottomText);

            var topTextPositionX = 20;
            var topTextPositionY = canvasHeight - 90;

            var bottomTextPositionX = 20;
            var bottomTextPositionY = canvasHeight - 32;

            var topTextPositionXBackground = topTextPositionX - 10;
            var topTextPositionYBackground = topTextPositionY - 35;

            var bottomTextPositionXBackground = bottomTextPositionX - 10;
            var bottomTextPositionYBackground = bottomTextPositionY - 35;

            if (textAlign == 'right') {
                var topTextPositionX = canvasWidth - 20;
                var bottomTextPositionX = canvasWidth - 20;

                var topTextPositionXBackground = canvasWidth - topMeasure.width - 30;
                var bottomTextPositionXBackground = canvasWidth - bottomMeasure.width - 30;
            }

            if (textAlignVertical == 'top') {
                var topTextPositionY = 50;
                var bottomTextPositionY = 110;

                var topTextPositionYBackground = 15;
                var bottomTextPositionYBackground = 75;
            }

            if (topText != '') {
                ctx.beginPath();
                ctx.fillStyle = textFontBackgroundColor;
                ctx.fillRect(topTextPositionXBackground, topTextPositionYBackground, topMeasure.width + 20, 50);
                ctx.stroke();

                ctx.fillStyle = textFontColor;

                ctx.fillText(topText, topTextPositionX, topTextPositionY);
            }

            if (bottomText != '') {
                ctx.beginPath();
                ctx.fillStyle = textFontBackgroundColor;
                ctx.fillRect(bottomTextPositionXBackground, bottomTextPositionYBackground, bottomMeasure.width + 20, 50);
                ctx.stroke();

                ctx.fillStyle = textFontColor;

                ctx.fillText(bottomText, bottomTextPositionX, bottomTextPositionY);
            }
        }

    }

    return (
        <div>
            <div>
                <canvas
                    ref={canvas}
                    width={canvasWidth}
                    height={canvasHeight}
                ></canvas>
            </div>
            <div>
                <input type="text" value={topText} onChange={e => setTopText(e.target.value)}></input>{' '}
                <input type="text" value={bottomText} onChange={e => setBottomText(e.target.value)}></input>
            </div>
            <div>
                <ButtonGroup aria-label="Basic example">
                    <Button variant="primary" onClick={() => setTextAlign('left')}>Left</Button>
                    <Button variant="primary" onClick={() => setTextAlign('right')}>Right</Button>
                </ButtonGroup>
            </div>
            <div>
                <ButtonGroup aria-label="Basic example">
                    <Button variant="primary" onClick={() => setTextAlignVertical('top')}>Top</Button>
                    <Button variant="primary" onClick={() => setTextAlignVertical('bottom')}>Bottom</Button>
                </ButtonGroup>
            </div>
            <div className="col-2">
                <FormSelect onChange={e => { setTextFont(e.target.value) }}>
                    {/* <option>Font family</option> */}
                    <option value="Verdana">Verdana</option>
                    <option value="Arial">Arial</option>
                    <option value="Comic Sans MS">Comic Sans MS</option>
                </FormSelect>
            </div>
            <div className="row">
                <div className="col-2">
                    <HexColorPicker color={textFontColor} onChange={setTextFontColor} />
                </div>
                <div className="col-2">
                    <HexColorPicker color={textFontBackgroundColor} onChange={setTextFontBackgroundColor} />
                </div>
            </div>

        </div >
    );
}

export default Playground;