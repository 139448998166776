import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import './App.css';
import './theme/css/style.css';
import './theme/css/responsive.css';

import LandingPage from './components/LandingPage'
import Login from './components/Login'
import Register from './components/Register'
import Reset from './components/Reset'
import Editor from './components/Editor'
import Playground from './components/Playground'

function App() {
  return (
    <Suspense fallback="loading">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<Login />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/reset" element={<Reset />} />
          <Route path="/editor" element={<Editor />} />
          <Route path="/playground" element={<Playground />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
