import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { useTranslation, Trans } from "react-i18next";

import { auth, registerWithEmailAndPassword, signInWithGoogle, signInWithFacebook } from "../firebase";

import ils_09 from './../theme/images/assets/ils_09.svg';
import shape_25 from './../theme/images/shape/shape_25.svg';
import icon_10 from './../theme/images/icon/icon_10.svg';

function Register2() {
    const { t } = useTranslation();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [name, setName] = useState("");
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();

    const register = () => {
        if (!name) alert("Please enter name");
        registerWithEmailAndPassword(name, email, password);
    };

    useEffect(() => {
        if (loading) return;
        if (user) navigate("/editor");
    }, [user, loading]);
    return (

        <div className="main-page-wrapper">

            <div className="fancy-feature-twelve mt-10 lg-mt-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 col-md-6 order-md-last">
                            <div className="block-style-nine pt-30 sm-pt-10">
                                <div className="title-style-one pb-10" data-aos="fade-up">
                                    <div className="sc-title-four">JARRO AI</div>
                                    <h2 className="main-title">{t("register")}!</h2>
                                </div>

                                <div className="mt-40 d-flex" data-aos="fade-up" data-aos-delay="100">
                                    <div className="block-style-four">
                                        <div className="icon d-flex align-items-end justify-content-center"><img src={icon_10} alt="" /></div>


                                        <div className="form-style-one">
                                            <div className="form">
                                                {/* <form id="contact-form" data-toggle="validator"> */}
                                                <div className="messages"></div>
                                                <div className="row controls" style={{ Padding: "20px 25px 20px 95px" }}>

                                                    <div className="col-12">
                                                        <div className="input-group-meta form-group mb-30">
                                                            <br />
                                                            <button className="social-media" onClick={signInWithGoogle}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 262" preserveAspectRatio="xMidYMid" style={{ marginRight: "12px" }}><path d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027" fill="#4285F4"></path><path d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1" fill="#34A853"></path><path d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782" fill="#FBBC05"></path>
                                                                    <path d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251" fill="#EB4335"></path></svg>
                                                                {t("continue-with")} Google
                                                            </button><br />
                                                            <button className="social-media" onClick={signInWithFacebook}>
                                                                <svg enableBackground="new 0 0 24 24" height="24" width="24" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: "12px", Width: "20", Height: "20" }}><path d="m21 0h-18c-1.655 0-3 1.345-3 3v18c0 1.654 1.345 3 3 3h18c1.654 0 3-1.346 3-3v-18c0-1.655-1.346-3-3-3z" fill="#4267B1"></path><path d="m16.5 12v-3c0-.828.672-.75 1.5-.75h1.5v-3.75h-3c-2.486 0-4.5 2.014-4.5 4.5v3h-3v3.75h3v8.25h4.5v-8.25h2.25l1.5-3.75z" fill="#fff"></path></svg>
                                                                {t("continue-with")} Facebook
                                                            </button><br />
                                                            <label> {t("or")} </label>
                                                            <input
                                                                type="text"
                                                                className="input100"
                                                                value={name}
                                                                onChange={(e) => setName(e.target.value)}
                                                                placeholder={t("full-name")}
                                                            />

                                                            <div className="help-block with-errors"></div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12">
                                                        <div className="input-group-meta form-group mb-30">
                                                            <input
                                                                type="text"
                                                                value={email}
                                                                onChange={(e) => setEmail(e.target.value)}
                                                                placeholder="e-mail"
                                                            />

                                                            <div className="help-block with-errors"></div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12">
                                                        <div className="input-group-meta form-group mb-30">
                                                            <input
                                                                type="password"
                                                                value={password}
                                                                onChange={(e) => setPassword(e.target.value)}
                                                                placeholder={t("password")}
                                                            />

                                                            <div className="help-block with-errors"></div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12"><button className="btn-eight ripple-btn" onClick={register}>{t("sign-up")}</button></div>

                                                    <br />
                                                    <br />
                                                    <div className="text-center">
                                                        <label style={{ display: "initial" }}>{t("already-have-an-account")} </label><Link to="/login">{t("sign-in")}</Link><br />
                                                        <label style={{ display: "initial" }}>{t("lost-your-password")} </label><Link to="/reset">{t("reset-here")}</Link>
                                                    </div>
                                                </div>
                                                {/* </form> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-7 col-md-6 order-md-first" data-aos="fade-right">
                            <div className="illustration-holder position-relative d-inline-block ms-5 sm-mt-30">
                                <img src={ils_09} alt="" className="transform-img-meta" />
                            </div>
                        </div>
                    </div>
                </div>
                <img src={shape_25} alt="" className="shapes bg-shape" />
            </div >
        </div >
    );
}
export default Register2;