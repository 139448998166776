import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { query, collection, getDocs, where } from "firebase/firestore";
import TextareaAutosize from 'react-textarea-autosize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRightFromBracket, faUser, faSpinner } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { WithContext as ReactTags } from 'react-tag-input';
import { useTranslation, Trans } from "react-i18next";

import { default as InstaPost } from "./InstaPost";
import { default as FacePost } from "./FacePost";
import { auth, db, logout } from "../firebase";
import logo from './../images/jarro_2.png';

import './EditorStyle.css';
import './ReactTags.css';

var API_URL = "https://us-central1-jarro-ai.cloudfunctions.net/";
// var API_URL = "http://localhost:3000/";
// var API_URL = "http://localhost:5001/jarro-ai/us-central1/";

const TOKEN_PRICE = 0.02;

function Editor(props) {
  const { t } = useTranslation();

  const [details, setDetails] = useState('');
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [requestCount, setRequestCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [historyList, setHistoryList] = useState([]);
  const [showExamples, setShowExamples] = useState(false);
  const [tags, setTags] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [photoIndexMax, setPhotoIndexMax] = useState(1);
  const [alreadyMadeRequest, setAlreadyMadeRequest] = useState(false);

  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");
  const [tokens, setTokens] = useState(0);
  const navigate = useNavigate();

  const fetchUserName = async () => {
    try {
      const q = query(collection(db, "users_v2"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      setName(data.name);
      setTokens(data.tokens);
      setRequestCount(data.requests);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getPhotos();
  }, []);

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
    fetchUserName();
  }, [user, loading]);


  const handleDetailsChange = (event) => {
    setDetails(event.target.value);
  }

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  }

  const handleBodyChange = (event) => {
    setBody(event.target.value);
  }

  const formatBody = (newContent, fromScratch) => {
    newContent = newContent.toString();

    if (!fromScratch) {
      newContent = body + newContent;
    }

    setBody(newContent);
  }

  const handleCloseHistory = () => setShowHistory(false);

  const handleShowHistory = (event) => {
    setShowHistory(true)

    fetch(API_URL + `getUserHistory?user=${user?.uid}`)
      .then(res => res.json())
      .then(
        (result) => {
          setHistoryList(result.requests);
          // console.log(result);
        },
        (error) => {
          console.log(error);
        }
      )

    event.preventDefault();
  }

  const handleCloseExamples = () => setShowExamples(false);
  const handleShowExamples = () => setShowExamples(true);

  const getPhotos = () => {

    // var query = details;
    var query = 'beach';

    if (tags.length > 0) {
      query = ' ';
      for (const tag of tags) {
        query = query + tag.id + ' ';
      }

      query = query.trim();
    }

    // console.log(query);

    fetch(API_URL + `getPhotos?query=${query}`)
      .then(res => res.json())
      .then(
        (result) => {
          if (result.data && result.data.results.length > 0) {
            setPhotoIndexMax(result.data.total <= 10 ? result.data.total : 10);
            setPhotos(result.data.results);
          }
          else {
            setPhotoIndexMax(0);
            setPhotos([]);
          }
        },
        (error) => {
          console.log(error);
        }
      )
  }

  const handleGenerateContentFromScratch = async (event) => {
    setBody('');

    generateContent(true);

    event.preventDefault();
  }

  const handleGenerateContentFromText = (event) => {
    // console.log('handleGenerateContentFromText')
    generateContent(false);

    event.preventDefault();
  }

  const generateContent = (fromScratch) => {
    setIsLoading(true);

    var content = body;
    if (fromScratch) {
      content = '';
    }

    fetch(API_URL + `getContent?details=${details}&user=${user?.uid}`)
      .then(res => res.json())
      .then(
        (result) => {

          setAlreadyMadeRequest(true);
          setRequestCount(requestCount + 1);
          setIsLoading(false);

          console.log(result.tokens);
          setTokens(result.tokens);
          formatBody(result.answer.choices[0].text, fromScratch);

          getPhotos();
        },
        (error) => {
          setIsLoading(false);
          console.log(error);
        }
      )
  }

  const copyContent = () => {
    navigator.clipboard.writeText(body);
  }

  const randomNumber = (min, max) => {
    // console.log(max);
    var number = Math.floor(Math.random() * (max - min) + min);
    // console.log(number);
    return number;
  }

  const changePhoto = () => {
    setPhotoIndex(randomNumber(0, photoIndexMax))
  }

  const KeyCodes = {
    tab: 9,
    comma: 188,
    enter: 13
  };

  const handleDelete = i => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = async tag => {
    await removeHashtagFromTag(tag);

    setTags([...tags, tag]);
  };

  const handleTagClick = index => {
    // console.log('The tag at index ' + index + ' was clicked');
  };

  const handleTagInputBlur = tag => {
    if (tag.trim() != '') {
      handleAddition({ id: tag, text: tag });
    }
  };

  const removeHashtagFromTag = async (tag) => {
    tag.id = tag.id.replace('#', '');
    tag.text = tag.id;
  }

  return (
    <>
      <div className="Editor">
        <header className="theme-main-menu sticky-menu theme-menu-two fixed" style={{
          backgroundColor: "#3c6aff", borderBottomLeftRadius: "20px",
          borderBottomRightRadius: "20px"
        }}>
          <div className="inner-content" style={{ color: "#f8f9fa" }}>
            <div className="container d-flex align-items-center justify-content-between">
              <img src={logo} alt="" width="80" /> {/*<span>Jarro AI</span> */}
              <div><span style={{ marginRight: 20 }}><FontAwesomeIcon icon={faUser} /> {name.split(" ")[0]}</span>
                <FontAwesomeIcon icon={faRightFromBracket} onClick={logout} style={{ cursor: "pointer" }} />
              </div>
            </div>
          </div>
        </header>

        <div style={{ marginTop: "0px" }}>


          <div className="hero-banner-four" style={{ padding: "110px 0 0px" }}>
            <div className="container">
              <div className="row">
                <div className="col-xl-8 col-xl-7 col-lg-8 col-md-11 m-auto">
                  <p className="mb-50 lg-mb-20">{t("ask-about-anything")} </p>

                  <div className="row">
                    <div className="col-3" style={{ textAlign: "initial" }}>
                      <span style={{ fontSize: "12px", cursor: "pointer" }} onClick={handleShowExamples}>{t('examples!')}</span>
                    </div>
                    <div className="col-9">
                      <div style={{ textAlign: "end", fontSize: "12px" }}>
                        <span style={{ color: "#7c7c7c" }}>{t('youve-made-n-requests', { requestCount: requestCount })} </span>
                        {requestCount > 0 && (
                          <>
                            · {tokens} tokens · <span onClick={handleShowHistory} style={{ cursor: "pointer" }}>{t('show-history')}</span>
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="form" action="#" style={{ maxWidth: "900px" }}>
                    <input type="text" value={details} autoFocus onChange={handleDetailsChange} placeholder={t('create-a-text-about-healthy-diet')}></input>
                    <button onClick={handleGenerateContentFromScratch} style={{ background: "#010d4c" }} disabled={details.length == 0}>
                      {!isLoading && (
                        <span>{t('create')}</span>
                      )}
                      {isLoading && (
                        <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                      )}
                    </button>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="info" style={{ marginTop: "10px" }}>
                        <ReactTags
                          tags={tags}
                          classNames={{
                            tagInputField: 'ReactsTags-FormControl',
                          }}
                          delimiters={[KeyCodes.tab, KeyCodes.comma, KeyCodes.enter]}
                          handleDelete={handleDelete}
                          handleAddition={handleAddition}
                          handleTagClick={handleTagClick}
                          handleInputBlur={handleTagInputBlur}
                          inputFieldPosition="top"
                          autofocus={false}
                          placeholder={t('add-tags')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {(alreadyMadeRequest) && (
            <div className="container">
              <div className="inner-wrapper">
                <hr></hr>

                <div className="row" style={{ paddingLeft: "calc(var(--bs-gutter-x)/ 2)", paddingRight: "calc(var(--bs-gutter-x)/ 2)" }}>
                  <div className="col-12" style={{ padding: "20px", border: "1px solid #ced4da", backgroundColor: "white", borderRadius: "20px" }}>
                    <div className="row mb-20">
                      <div className="col-12">
                        <TextareaAutosize placeholder={t('you-can-begin-creating')} id="txtBody" value={body} onChange={handleBodyChange} className="form-control" style={{ overflow: "hidden", overflowWrap: "breakWord", height: 30, resize: "none", border: "0px", fontSize: "18px" }}></TextareaAutosize>
                        {body.length > 0 && (
                          <>
                            <Button className="btn btn-success mt-10" size="sm" onClick={handleGenerateContentFromText} >{t('write-more')}</Button>{'  '}
                            <span onClick={copyContent} style={{ cursor: "pointer", fontSize: 15 }}>{t('copy')}</span>
                          </>
                        )}

                      </div>
                    </div>
                    <hr></hr>
                    <div className="row mt-20">
                      <div className="col-xl-6 col-md-6 mb-10">
                        <span>Instagram</span>
                        <InstaPost content={body} onContentChange={handleBodyChange} handleGenerateContentFromText={handleGenerateContentFromText} photo={photos[photoIndex]} changePhoto={changePhoto}></InstaPost>
                      </div>
                      <div className="col-xl-6 col-md-6">
                        <span>Facebook</span>
                        <FacePost content={body} onContentChange={handleBodyChange} handleGenerateContentFromText={handleGenerateContentFromText} photo={photos[photoIndex]} changePhoto={changePhoto}></FacePost>
                      </div>
                    </div>

                    {/* <div>
                      <InstaPost content={body} onContentChange={handleBodyChange} handleGenerateContentFromText={handleGenerateContentFromText} photo={photos[photoIndex]} changePhoto={changePhoto}></InstaPost>
                    </div>
                    <div>
                      <FacePost content={body} onContentChange={handleBodyChange} handleGenerateContentFromText={handleGenerateContentFromText} photo={photos[photoIndex]} changePhoto={changePhoto}></FacePost>
                    </div> */}
                  </div>
                </div>


              </div>
            </div>
          )}
        </div >


      </div >

      <Modal show={showExamples} onHide={handleCloseExamples} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{t('some-examples-and-tips')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Trans i18nKey="first-you-must-be-clear"></Trans>
          <br />
          <ul style={{ fontSize: 18 }}>
            <li>{t('example-tip-1')}</li>
            <li>{t('example-tip-2')}</li>
            <li>{t('example-tip-3')}</li>
            <li>{t('example-tip-4')}</li>
            <li>{t('example-tip-5')}</li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseExamples}>
            {t('close')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showHistory} onHide={handleCloseHistory} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{t('all-request-youve-made')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {historyList.map((request, i) => {
            // console.log(request);
            // Return the element. Also pass key     
            return (
              <div style={{ marginBottom: 20, lineHeight: 1.45, fontSize: 14 }}>
                <div style={{ fontWeight: 'bold' }}>{request.details}</div>
                <div>{t('title')} {request.title}</div>
                <div>{t('body')} {request.body}</div>
                <div className="cyTuUz" style={{ borderColor: 'black', borderColor: '#484848' }}>{t('answer')} {request.answer}</div>
                <hr />
              </div>
            )
          })}

          <span style={{ fontSize: 15 }}>{t('every-tiime-you-hit')}</span>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseHistory}>
            {t('close')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Editor;