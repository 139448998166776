import React, { useEffect, useState } from "react";
import TextareaAutosize from 'react-textarea-autosize';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRotateRight } from '@fortawesome/free-solid-svg-icons';
import { useTranslation, Trans } from "react-i18next";

import like from './images/like.svg';
import haha from './images/haha.svg';
import love from './images/love.svg';

import './FacePost.css';

function FacePost(props) {
    const { t } = useTranslation();

    return (
        <div className="FacePost">
            <div className="postcontainer">
                <div className="post">
                    <div className="post__header header">
                        <div className="info">
                            <div className="user">
                                <div className="profile-pic"><img src={require("./img/cover 1.png")} alt="" /></div>
                                <p className="username">{t('mycompany')}</p>
                            </div>
                            <img src={require("./img/option.png")} className="options" alt="" />
                        </div>

                    </div>
                    <div className="post__content content">
                        <p className="content__paragraph">
                            <TextareaAutosize placeholder={t("you-can-begin-creating")} id="txtBody" value={props.content} onChange={props.onContentChange} className="form-control" style={{ overflow: "hidden", overflowWrap: "breakWord", height: 30, resize: "none", border: "0px", fontSize: "15px" }}></TextareaAutosize>
                            {props.content && props.content.length > 0 && (
                                <Button className="btn btn-success mt-10" size="sm" onClick={props.handleGenerateContentFromText}>{t('write-more')}</Button>
                            )}
                        </p>
                        {props.photos && props.photos.length == 0 && (
                            <img src={require("../images/photo-1470252649378-9c29740c9fa8.jpeg")} className="content__image" />
                        )}
                        {props.photos && props.photos.length > 0 && (
                            <div style={{ float: "left", position: "relative", width: "100%", cursor: "pointer" }} onClick={props.changePhoto}>
                                <img src={props.photos[props.photoIndex].urls.small} className="content__image" />
                                <div style={{ position: "absolute", right: "50%", top: "50%" }}><FontAwesomeIcon icon={faArrowRotateRight} className="reloadIcon" /></div>
                            </div>
                        )}
                    </div>
                    <div className="post__footer footer">
                        <div className="footer__reactions reactions">
                            <div className="reactions__emojis emojis">
                                <img src={like} className="emojis__like" />
                                <img src={haha} className="emojis__haha" />
                                <img src={love} className="emojis__love" />
                                <span className="emojis__count">
                                    <a href="#">193K</a>
                                </span>
                            </div>
                            <div className="reactions__comment-shares">
                                <span className="comment-shares__comments"><a href="#">50K {t("comments")}</a></span>
                                <span className="comment-shares__shares"><a href="#">5.4K {t("shares")}</a></span>
                            </div>
                        </div>
                        <div className="footer__buttons buttons">
                            <span className="buttons__like like">
                                <i className="like__icon"></i>{t("like")}
                            </span>
                            <span className="buttons__comment comment"><i className="comment__icon"></i>{t("comment")}</span>
                            <span className="buttons__share share"><i className="share__icon"></i>{t("share")}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FacePost;