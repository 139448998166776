import React, { useEffect, useState, useRef } from "react";
import TextareaAutosize from 'react-textarea-autosize';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRotateRight } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from "react-i18next";
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import FormSelect from 'react-bootstrap/FormSelect'
import { HexColorPicker } from "react-colorful";

import Canvas from './Canvas';
import './InstaPost.css';

function InstaPost(props) {
    const { t } = useTranslation();

    const canvas = useRef(null);
    const [canvasWidth, setCanvasWidth] = useState(512);
    const [canvasHeight, setCanvasHeight] = useState(512);
    // const [image, setImage] = useState(null);
    const [topText, setTopText] = useState("");
    const [bottomText, setBottomText] = useState("");
    const [textAlign, setTextAlign] = useState("left");
    const [textAlignVertical, setTextAlignVertical] = useState("bottom");
    const [textSize, setTextSize] = useState("30");
    const [textFont, setTextFont] = useState("Verdana");
    const [textFontColor, setTextFontColor] = useState("black");
    const [textFontBackgroundColor, setTextFontBackgroundColor] = useState("white");
    const [photoRef, setPhotoRef] = useState(props.photoIndex);

    useEffect(() => {
        // console.log('useEffect instapost')
        // const catImage = new Image();
        // catImage.src = "https://thiscatdoesnotexist.com"
        // catImage.onload = () => setImage(catImage);
    }, [])

    useEffect(() => {
        updateCanvas();
    }, [canvas, topText, bottomText, textAlign, textAlignVertical, textFont, textFontColor, textFontBackgroundColor])

    const draw = ctx => {

        const postImage = new Image();
        postImage.src = props.photo.urls.small;

        ctx.drawImage(postImage, 0, 0);

        // Insert your canvas API code to draw an image
    };

    const updateCanvas = () => {
        // console.log('updateCanvas');
        if (canvas && props.photos && props.photos.length > 0) {
            const ctx = canvas.current.getContext("2d");

            // const postImage = new Image();
            // postImage.src = props.photos[props.photoIndex].urls.small;

            // ctx.drawImage(postImage, 0, 0);

            ctx.font = `${textSize}px ${textFont}`
            ctx.textAlign = textAlign;

            let topMeasure = ctx.measureText(topText);
            let bottomMeasure = ctx.measureText(bottomText);

            var topTextPositionX = 20;
            var topTextPositionY = canvasHeight - 90;

            var bottomTextPositionX = 20;
            var bottomTextPositionY = canvasHeight - 32;

            var topTextPositionXBackground = topTextPositionX - 10;
            var topTextPositionYBackground = topTextPositionY - 35;

            var bottomTextPositionXBackground = bottomTextPositionX - 10;
            var bottomTextPositionYBackground = bottomTextPositionY - 35;

            if (textAlign == 'right') {
                var topTextPositionX = canvasWidth - 20;
                var bottomTextPositionX = canvasWidth - 20;

                var topTextPositionXBackground = canvasWidth - topMeasure.width - 30;
                var bottomTextPositionXBackground = canvasWidth - bottomMeasure.width - 30;
            }

            if (textAlignVertical == 'top') {
                var topTextPositionY = 50;
                var bottomTextPositionY = 110;

                var topTextPositionYBackground = 15;
                var bottomTextPositionYBackground = 75;
            }

            if (topText != '') {
                ctx.beginPath();
                ctx.fillStyle = textFontBackgroundColor;
                ctx.fillRect(topTextPositionXBackground, topTextPositionYBackground, topMeasure.width + 20, 50);
                ctx.stroke();

                ctx.fillStyle = textFontColor;

                ctx.fillText(topText, topTextPositionX, topTextPositionY);
            }

            if (bottomText != '') {
                ctx.beginPath();
                ctx.fillStyle = textFontBackgroundColor;
                ctx.fillRect(bottomTextPositionXBackground, bottomTextPositionYBackground, bottomMeasure.width + 20, 50);
                ctx.stroke();

                ctx.fillStyle = textFontColor;

                ctx.fillText(bottomText, bottomTextPositionX, bottomTextPositionY);
            }
        }

    }

    return (
        <div className="InstaPost">
            <div className="row">
                <div className="col-xl-12 col-md-12 mb-10">
                    <div className="wrapper2">
                        <div className="left-col">
                            <div className="post">
                                <div className="info-insta">
                                    <div className="user">
                                        <div className="profile-pic"><img src={require("./img/cover 1.png")} alt="" /></div>
                                        <p className="username">{t('mycompany')}</p>
                                    </div>
                                    <img src={require("./img/option.png")} className="options" alt="" />
                                </div>
                                {props.photo == null && (
                                    <>
                                        <img src={require("../images/photo-1470252649378-9c29740c9fa8.jpeg")} className="post-image" alt="" />
                                    </>
                                )}
                                {props.photo != null && (
                                    <>

                                        {/* <Canvas draw={draw} height={100} width={100} /> */}

                                        {/* <canvas
                                            ref={canvas}
                                            width="200px"
                                            height="500px"
                                        ></canvas> */}

                                        <div style={{ float: "left", position: "relative", width: "100%", cursor: "pointer" }} onClick={props.changePhoto}>
                                            <Canvas src={props.photo.urls.small}></Canvas>
                                            {/* <img src={props.photo.urls.small} className="post-image" alt="" /> */}
                                            <div style={{ position: "absolute", right: "50%", top: "50%" }}><FontAwesomeIcon icon={faArrowRotateRight} className="reloadIcon" /></div>
                                        </div>
                                    </>
                                )}

                                <div className="post-content">
                                    <div className="reaction-wrapper">
                                        <img src={require("./img/like.png")} className="icon" alt="" />
                                        <img src={require("./img/comment.png")} className="icon" alt="" />
                                        <img src={require("./img/send.png")} className="icon" alt="" />
                                        <img src={require("./img/save.png")} className="save icon" alt="" />
                                    </div>
                                    <p className="likes">1,012 {t('likes')}</p>
                                    <p className="description"><span>{t('mycompany')}</span>
                                        <TextareaAutosize placeholder={t("you-can-begin-creating")} id="txtBody" value={props.content} onChange={props.onContentChange} className="form-control" style={{ overflow: "hidden", overflowWrap: "breakWord", minRows: "50", resize: "none", border: "0px", fontSize: "15px" }}></TextareaAutosize>
                                        {props.content && props.content.length > 0 && (
                                            <Button className="btn btn-success mt-10" size="sm" onClick={props.handleGenerateContentFromText} >{t('write-more')}</Button>
                                        )}
                                    </p>
                                    <p className="post-time">2 {t('minutes-ago')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="col-xl-6 col-md-6">

                    <div>
                        <input type="text" value={topText} onChange={e => setTopText(e.target.value)}></input>{' '}
                        <input type="text" value={bottomText} onChange={e => setBottomText(e.target.value)}></input>
                    </div>
                    <div>
                        <ButtonGroup aria-label="Basic example">
                            <Button variant="primary" onClick={() => setTextAlign('left')}>Left</Button>
                            <Button variant="primary" onClick={() => setTextAlign('right')}>Right</Button>
                        </ButtonGroup>
                    </div>
                    <div>
                        <ButtonGroup aria-label="Basic example">
                            <Button variant="primary" onClick={() => setTextAlignVertical('top')}>Top</Button>
                            <Button variant="primary" onClick={() => setTextAlignVertical('bottom')}>Bottom</Button>
                        </ButtonGroup>
                    </div>
                    <div className="col-2">
                        <FormSelect onChange={e => { setTextFont(e.target.value) }}>
                            <option value="Verdana">Verdana</option>
                            <option value="Arial">Arial</option>
                            <option value="Comic Sans MS">Comic Sans MS</option>
                        </FormSelect>
                    </div>
                    <div className="row">
                        <div className="col-2">
                            <HexColorPicker color={textFontColor} onChange={setTextFontColor} />
                        </div>
                        <div className="col-2">
                            <HexColorPicker color={textFontBackgroundColor} onChange={setTextFontBackgroundColor} />
                        </div>
                    </div>

                </div> */}
            </div>
        </div >
    );
}

export default InstaPost;