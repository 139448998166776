import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { auth, sendPasswordReset } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useTranslation, Trans } from "react-i18next";

import ils_09 from './../theme/images/assets/ils_09.svg';
import shape_25 from './../theme/images/shape/shape_25.svg';
import icon_10 from './../theme/images/icon/icon_10.svg';

function Reset() {
    const { t } = useTranslation();

    const [email, setEmail] = useState("");
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();
    useEffect(() => {
        if (loading) return;
        if (user) navigate("/dashboard");
    }, [user, loading]);
    return (

        <div className="main-page-wrapper">

            <div className="fancy-feature-twelve mt-10 lg-mt-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 col-md-6 order-md-last">
                            <div className="block-style-nine pt-30 sm-pt-10">
                                <div className="title-style-one pb-10" data-aos="fade-up">
                                    <div className="sc-title-four">JARRO AI</div>
                                    <h2 className="main-title">{t("reset-your-password")}</h2>
                                </div>

                                <div className="mt-40 d-flex" data-aos="fade-up" data-aos-delay="100">
                                    <div className="block-style-four">
                                        <div className="icon d-flex align-items-end justify-content-center"><img src={icon_10} alt="" /></div>


                                        <div className="form-style-one">
                                            <div className="form">
                                                {/* <form id="contact-form" data-toggle="validator"> */}
                                                <div className="messages"></div>
                                                <div className="row controls" style={{ Padding: "20px 25px 20px 95px" }}>
                                                    <div className="col-12">
                                                        <div className="input-group-meta form-group mb-30">
                                                            <br />
                                                            {/* <label>Name*</label> */}
                                                            <input
                                                                type="text"
                                                                value={email}
                                                                onChange={(e) => setEmail(e.target.value)}
                                                                placeholder="e-mail"
                                                            />

                                                            {/* <input type="text" placeholder="email@example.com" name="name" required="required" data-error="Name is required."></input> */}
                                                            <div className="help-block with-errors"></div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12"><button className="btn-eight ripple-btn" onClick={() => sendPasswordReset(email)} >
                                                        {t("send-password-reset-email")}</button></div>

                                                    <br />
                                                    <br />
                                                    <div className="text-center">
                                                        <label style={{ display: "initial" }}>{t("already-have-an-account")} </label><Link to="/login">{t("sign-in")}</Link><br />
                                                        <label style={{ display: "initial" }}>{t("dont-have-an-account")} </label><Link to="/register">{t("register-here")}</Link><br />
                                                    </div>
                                                </div>
                                                {/* </form> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-7 col-md-6 order-md-first" data-aos="fade-right">
                            <div className="illustration-holder position-relative d-inline-block ms-5 sm-mt-30">
                                <img src={ils_09} alt="" className="transform-img-meta" />
                            </div>
                        </div>
                    </div>
                </div>
                <img src={shape_25} alt="" className="shapes bg-shape" />
            </div >
        </div >
    );
}
export default Reset;