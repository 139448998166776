import { Link } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

import logo from './../images/jarro_blue.png';
import ils_04 from './../theme/images/assets/ils_04.svg';
import ils_04_1 from './../theme/images/assets/ils_04_1.svg';
import ils_04_2 from './../theme/images/assets/ils_04_2.svg';
import ils_04_3 from './../theme/images/assets/ils_04_3.svg';
import ils_04_4 from './../theme/images/assets/ils_04_4.svg';
import ils_04_5 from './../theme/images/assets/ils_04_5.svg';
import ils_04_6 from './../theme/images/assets/ils_04_6.svg';
import ils_05 from './../theme/images/assets/ils_05.svg';
import ils_05_1 from './../theme/images/assets/ils_05_1.svg';
import ils_05_2 from './../theme/images/assets/ils_05_2.svg';
import ils_05_3 from './../theme/images/assets/ils_05_3.svg';
import ils_05_4 from './../theme/images/assets/ils_05_4.svg';
import ils_05_5 from './../theme/images/assets/ils_05_5.svg';
import ils_06 from './../theme/images/assets/ils_06.svg';
import ils_06_1 from './../theme/images/assets/ils_06_1.svg';
import ils_06_2 from './../theme/images/assets/ils_06_2.svg';
import ils_06_3 from './../theme/images/assets/ils_06_3.svg';
import ils_06_4 from './../theme/images/assets/ils_06_4.svg';
import ils_06_5 from './../theme/images/assets/ils_06_5.svg';
import shape_04 from './../theme/images/shape/shape_04.svg';
import shape_05 from './../theme/images/shape/shape_05.svg';
import shape_10 from './../theme/images/shape/shape_10.svg';
import shape_11 from './../theme/images/shape/shape_11.svg';

function LandingPage() {
    const { t } = useTranslation();

    return (

        <div className="main-page-wrapper">

            {/* Theme Main Menu */}
            <header className="theme-main-menu sticky-menu theme-menu-two">
                <div className="inner-content">
                    <div className="container d-flex align-items-center justify-content-between">
                        <div className="logo order-lg-0"><a href="#" className="d-block"><img src={logo} alt="" height={50} /></a></div>
                        {/* <a href="contact-us.html" className="get-in-touch-btn d-none d-lg-block order-lg-3">Sign Up</a> */}
                        <Link to="/login" className="get-in-touch-btn d-none d-lg-block order-lg-3">{t("sign-up")}</Link>
                    </div>
                </div > {/* /.inner-content */}
            </header > {/* /.theme-main-menu */}

            {/* Theme Hero Banner */}
            <div className="hero-banner-two">
                <div className="bg-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-md-7">
                                <h1 className="hero-heading">{t("im-jarroai")}</h1>
                                <p className="text-lg pt-25 pb-40 lg-pb-20 sm-pt-10">{t("and-i-can-create")}</p>
                                <ul className="style-none button-group d-sm-flex align-items-center">
                                    {/* <li className="me-4 mt-10"><a href="#" className="btn-one ripple-btn"><Link to="/signin" className="btn-one ripple-btn">Sign Up</Link></a></li> */}
                                    <li className="me-4 mt-10"><Link to="/login" className="btn-one ripple-btn">{t("sign-up")}</Link></li>
                                    {/* <li><a className="demo-btn tran3s mt-10" href="contact-us.html">Request a demo</a></li> */}
                                </ul>
                            </div>
                        </div>
                    </div> {/* /.container */}
                    <div className="illustration-holder">
                        <img src={ils_04} alt="" className="main-illustration w-100" />
                        <img src={ils_04_1} alt="" className="shapes shape-one" />
                        <img src={ils_04_2} alt="" className="shapes shape-two" />
                        <img src={ils_04_2} alt="" className="shapes shape-three" />
                        <img src={ils_04_3} alt="" className="shapes shape-four" />
                        <img src={ils_04_4} alt="" className="shapes shape-five" />
                        <img src={ils_04_5} alt="" className="shapes shape-six" />
                        <img src={ils_04_6} alt="" className="shapes shape-seven" />
                    </div> {/* /.illustration-holder */}
                </div> {/* /.bg-wrapper */}
            </div> {/* /.hero-banner-two */}

            {/*
			=====================================================
				Counter Section One
			=====================================================
			*/}
            <div className="counter-section-one fancy-bg">
                <div className="container">
                    <div className="inner-container">
                        <div className="row justify-content-center">
                            <div className="col-md-4 col-sm-6" data-aos="fade-up">
                                <div className="counter-block-one text-center mb-20">
                                    <div className="main-count"><span className="counter">173</span>bi</div>
                                    <p><Trans i18nKey="machine-learnig-parameters"></Trans></p>
                                </div> {/* /.counter-block-one */}
                            </div>
                            <div className="col-md-4 col-sm-6" data-aos="fade-up" data-aos-delay="100">
                                <div className="counter-block-one text-center mb-20">
                                    <div className="main-count"><span className="counter">499</span>bi</div>
                                    <p><Trans i18nKey="tokens-of-knowledge"></Trans></p>
                                </div> {/* /.counter-block-one */}
                            </div>
                            <div className="col-md-4 col-sm-6" data-aos="fade-up" data-aos-delay="200">
                                <div className="counter-block-one text-center mb-20">
                                    <div className="main-count">$<span className="counter">15</span>k+</div>
                                    <p><Trans i18nKey="saved-operational-costs"></Trans></p>
                                </div> {/* /.counter-block-one */}
                            </div>
                        </div>
                    </div> {/* /.inner-container */}
                </div>
                <img src={shape_04} alt="" className="shapes shape-one" />
                <img src={shape_05} alt="" className="shapes shape-two" />
            </div> {/* /.counter-section-one */}


            {/* 
			=============================================
				Feature Section Two
			============================================== 
			*/}
            <div className="fancy-feature-two position-relative mt-200 lg-mt-120">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-5 col-lg-6 col-md-7 ms-auto">
                            <div className="block-style-two" data-aos="fade-left">
                                <div className="title-style-one">
                                    <div className="sc-title-three">{t("over-100-utilities")}</div>
                                    <h2 className="main-title">{t("world-best-ai")} </h2>
                                </div> {/* /.title-style-one */}
                                <p className="pt-10 pb-20 lg-pb-10">{t("ai-technology-is-perfect")}</p>
                                <ul className="style-none list-item color-rev">
                                    <li>{t("create-relevant-content")}</li>
                                    <li>{t("efficient-ads")}</li>
                                    <li>{t("creative-blog-posts")}</li>
                                    <li>{t("answers-for-almost")}</li>
                                </ul>
                                <div className="btn-three rev mt-55 lg-mt-30">{t("sign-up-for-free")} <Link to="/login" className="">{t("click-here")} <i className="fas fa-chevron-right"></i></Link></div>
                            </div> {/* /.block-style-two */}
                        </div>
                    </div>
                </div> {/* /.container */}
                <div className="illustration-holder-two sm-mt-40">
                    <img src={ils_05} alt="" className="main-illustration w-100" />
                    <img src={ils_05_1} alt="" className="shapes shape-one" />
                    <img src={ils_05_2} alt="" className="shapes shape-two" data-aos="fade-up" data-aos-anchor=".fancy-feature-two" data-aos-delay="100" data-aos-duration="2000" />
                    <img src={ils_05_3} alt="" className="shapes shape-three" data-aos="fade-up" data-aos-anchor=".fancy-feature-two" data-aos-delay="150" data-aos-duration="2000" />
                    <img src={ils_05_4} alt="" className="shapes shape-four" />
                    <img src={ils_05_5} alt="" className="shapes shape-five" />
                </div> {/* /.illustration-holder-two */}
            </div> {/* /.fancy-feature-two */}



            {/* 
			=============================================
				Feature Section Five
			============================================== 
			*/}
            <div className="fancy-feature-five position-relative mt-50 xs-mt-20">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xxl-4 col-lg-5 col-md-6">
                            <div className="block-style-five pt-60 md-pt-20" data-aos="fade-right">
                                <div className="title-style-one">
                                    <div className="sc-title-three">{t("best-ai-solution")}</div>
                                    <h2 className="main-title">{t("why-you-should")}</h2>
                                </div> {/* /.title-style-one */}
                                <p className="pt-10 pb-70">{t("tell-us-about")}</p>
                                <div className="skills-progress skills">
                                    <div className="habilidades_contenedor">
                                        <div className="codeconSkills">
                                            <div className="codeconSkillbar mb-80 md-mb-60">
                                                <div className="skill-text">
                                                    <span className="codeconSkillArea">{t("accuracy")}</span>
                                                </div>
                                                <div className="skillBar sk-fired" data-percent="80%" style={{ background: "rgb(242, 93, 255)", width: "73%" }}>
                                                    <span className="PercentText">80%</span>
                                                </div>
                                            </div>
                                            <div className="codeconSkillbar">
                                                <div className="skill-text">
                                                    <span className="codeconSkillArea ">{t("performance")}</span>

                                                </div>
                                                <div className="skillBar sk-fired" data-percent="91%" style={{ background: "rgb(0, 232, 250)", width: "91%" }}>
                                                    <span className="PercentText">91%</span>
                                                </div>
                                            </div>
                                        </div> {/* /.codeconSkills */}
                                    </div> {/* /.habilidades_contenedor */}
                                </div> {/* /.skills-progress */}
                                <Link to="/login" className="btn-five ripple-btn mt-60 lg-mt-50">{t("sign-up")}</Link>
                                {/* <a href="about-us1.html" className="btn-five ripple-btn mt-60 lg-mt-50">Sign Up</a> */}
                            </div> {/* /.block-style-five */}
                        </div>

                        <div className="col-xxl-8 col-lg-7 col-md-6 text-end">
                            <div className="illustration-holder d-inline-block position-relative xs-mt-20">
                                <img src={ils_06} alt="" className="main-illustration w-100" />
                                <img src={ils_06_1} alt="" className="shapes shape-one" />
                                <img src={ils_06_2} alt="" className="shapes shape-two" />
                                <img src={ils_06_3} alt="" className="shapes shape-three" data-aos="fade-down" data-aos-duration="1800" />
                                <img src={ils_06_4} alt="" className="shapes shape-four" data-aos="fade-left" data-aos-duration="1800" />
                                <img src={ils_06_5} alt="" className="shapes shape-five" />
                            </div>{/*  /.illustration-holder */}
                        </div>
                    </div>
                </div> {/* /.container */}
            </div> {/* /.fancy-feature-five */}



            {/* 
			=============================================
				Feature Section Six
			============================================== 
			*/}
            <div className="fancy-feature-six position-relative mt-80">
                <div className="container">
                    <div className="bg-wrapper">
                        <div className="row gx-xxl-5 justify-content-center">
                            <div className="col-lg-4 col-sm-6" data-aos="fade-up">
                                <div className="block-style-six text-center mt-40">
                                    <div className="icon" style={{ background: "rgba(68, 109, 255, 0.07)", color: "#446DFE" }}><i className="bi bi-check-lg"></i></div>
                                    <h6>{t("quality-support")}</h6>
                                    <p>{t("were-willing-to-help")}</p>
                                </div> {/* /.block-style-six */}
                            </div>
                            <div className="col-lg-4 col-sm-6" data-aos="fade-up" data-aos-delay="100">
                                <div className="block-style-six text-center mt-40">
                                    <div className="icon" style={{ background: "rgba(253, 232, 255, 1)", color: "#F36EFF" }}><i className="bi bi-check-lg"></i></div>
                                    <h6>{t("fastest-ai-machine")}</h6>
                                    <p>{t("you-wont-believe")}</p>
                                </div> {/* /.block-style-six */}
                            </div>
                            <div className="col-lg-4 col-sm-6" data-aos="fade-up" data-aos-delay="200">
                                <div className="block-style-six text-center mt-40">
                                    <div className="icon" style={{ background: "rgba(255, 122, 65, 0.12)", color: "#FF7A41" }}><i className="bi bi-check-lg"></i></div>
                                    <h6>{t("no-cost-at-all")}</h6>
                                    <p>{t("you-can-use-jarro")}</p>
                                </div> {/* /.block-style-six */}
                            </div>
                        </div>
                    </div> {/* /.bg-wrapper */}
                </div> {/* /.container */}
                <img src={shape_10} alt="" className="shapes shape-one" />
                <img src={shape_11} alt="" className="shapes shape-two" />
            </div> {/* /.fancy-feature-six */}



            {/*
			=====================================================
				Footer
			=====================================================
			*/}
            <div className="footer-style-two theme-basic-footer">
                <div className="container">
                    <div className="inner-wrapper">
                        <div className="bottom-footer">
                            <div className="d-lg-flex justify-content-between align-items-center">
                                {/* <ul className="order-lg-1 pb-15 d-flex justify-content-center footer-nav style-none">
                                    <li><a href="faq.html">Privacy &amp; Terms.</a></li>
                                    <li><a href="faq.html">FAQ</a></li>
                                    <li><a href="contact-us.html">Contact Us</a></li>
                                </ul> */}
                                <p className="copyright text-center order-lg-0 pb-15">Copyright @2022 Jarro AI.</p>
                            </div>
                        </div>
                    </div> {/* /.inner-wrapper */}
                </div>
            </div> {/* /.footer-style-two */}


            <button className="scroll-top">
                <i className="bi bi-arrow-up-short"></i>
            </button>


        </div >
    );
}
export default LandingPage;