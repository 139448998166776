import React, { useEffect, useRef, useState } from 'react';

function Canvas(props) {
    const canvas = useRef();
    const ghostImage = useRef();

    const [image, setImage] = useState(null);
    // const [width, setWidth] = useState(0);

    useEffect(() => {
        const postImage = new Image();
        postImage.src = props.src;
        postImage.onload = () => setImage(postImage);

        const ctx = canvas.current.getContext("2d");
        // console.log('use Effect [] draw')
        requestAnimationFrame(() => draw(ctx, postImage, 111, ghostImage.current.height));
        // requestAnimationFrame(() => draw(ctx, postImage, ghostImage.current.width, ghostImage.current.height));

        // setWidth(ghostImage.current.width);

        const handleResize = (e) => {
            // console.log('handleResize')
            // console.log(ghostImage.current.width)
            // console.log(ghostImage.current.height)
            // console.log(ghostImage.current.width);
            // console.log(ghostImage.current.height);
            // ctx.canvas.width = window.innerWidth;
            // ctx.canvas.height = window.innerHeight;
            ctx.canvas.width = ghostImage.current.width;
            ctx.canvas.height = ghostImage.current.height;
        };

        handleResize();
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);

    }, [])

    useEffect(() => {
        // console.log('useEffect [image]')
        const ctx = canvas.current.getContext("2d");

        // console.log('use Effect [image] draw')
        draw(ctx, image, 222, ghostImage.current.height);
        // draw(ctx, image, ghostImage.current.width, ghostImage.current.height);
    }, [image])

    const draw = (ctx, image, width, height) => {
        // console.log('draw');
        // console.log(canvas);
        // console.log(image);
        // console.log(width);
        if (canvas && image) {
            // console.log('draw if');
            const canvas = ctx.canvas;
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.fillStyle = "salmon";
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            ctx.fillStyle = "white";
            ctx.font = "50px sans-serif";
            ctx.fillText("Post image!", canvas.width / 2 - 100, canvas.height / 2, 200);

            // console.log(image);
            // console.log(width);
            // console.log(height);
            ctx.drawImage(image, 0, 0, ghostImage.current.width, ghostImage.current.height);
            // ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

            requestAnimationFrame(() => draw(ctx));
        }
    }

    // useEffect(() => {

    //     if (canvas && image) {
    //         const ctx = canvas.current.getContext("2d");
    //         ctx.drawImage(image, 0, 0);
    //     }

    // });

    return (
        <>
            <canvas ref={canvas} />
            <img ref={ghostImage} src={props.src} className="post-image" alt="" />

        </>
    );
};

export default Canvas;